@font-face {
  font-family: 'Avenixel';
  src: url('../assets/fonts/Avenixel-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

* {
  font-family: 'Avenixel', system-ui;
}
